import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../../services/document.service';
import { StudentDocument } from '../../../models/student-document.model';
import { Router } from '@angular/router';
import { StudentService } from '../../../services/student.service';
import { Student } from '../../../models/student.model';

@Component({
  selector: 'app-documents',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  documents: StudentDocument[] | undefined;
  student: Student | undefined;

  constructor(private documentService: DocumentService, private router: Router, private studentService: StudentService) {}

  ngOnInit(): void {
    this.documentService.getDocuments().subscribe({ 
      next: (documents) => {
        this.documents = documents;
      },
      error: (err) => {
        console.log(err);
      }
    })

    this.studentService.getStudentDetail().subscribe({
      next: (student) => {
        this.student = student;
      }
    });
  }

  goToDocument(document: StudentDocument) {
    if (document.studentDocumentId) {
      this.router.navigateByUrl(`/document/${document.studentDocumentId}`);
    } else {
      this.router.navigateByUrl(`/document/sign/${document.documentVersionId}`);
    }
  }
}
