<div *ngIf="currentStep == loginSteps.Login" class="flex flex-col w-full items-center gap-6 mt-12 px-8 lg:px-[40%]">
    <div>
        <img class="w-8 h-8" src="../../../assets/images/logo.svg"/>
    </div>
    <div class="w-full text-center text-gray-900 text-2xl font-semibold pb-8 border-b">
        Enter your email to sign-in
    </div>
    <div class="w-full">
        <input [(ngModel)]="email" name="email" type="text" placeholder="Enter your email..." [ngClass]="{'w-full border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg w-full mb-4': true, 'hover:bg-gray-50 border-gray-300 focus:border-primary-300 focus:ring-gray-100': !errorMessage, 'focus:ring-red-600 border-red-600 focus:border-red-600 target:border-red-600': errorMessage}" />
        <p *ngIf="errorMessage" class="text-center text-red-700 mb-4">{{errorMessage}}</p>
        <button [disabled]="loading || !email" (click)="login(false)" class="w-full h-11 px-4 py-2.5 bg-purple-600 disabled:bg-purple-400 rounded-lg shadow border border-purple-600 disabled:border-purple-400 justify-center items-center gap-2 inline-flex text-white text-base font-semibold">Send verification email</button>
    </div>
</div>

<div #verifyForm *ngIf="currentStep == loginSteps.Verify" class="flex flex-col w-full items-center gap-6 mt-12 px-8 lg:px-[40%]">
    <div>
        <div class="w-14 h-14 p-3.5 bg-white rounded-xl shadow border border-gray-200 justify-center items-center inline-flex">
            <div class="w-7 h-7 relative flex-col justify-start items-start flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                    <path d="M2.83337 8.16666L12.3591 14.8347C13.1305 15.3746 13.5162 15.6446 13.9357 15.7492C14.3063 15.8416 14.6938 15.8416 15.0644 15.7492C15.4839 15.6446 15.8696 15.3746 16.641 14.8347L26.1667 8.16666M8.43337 23.3333H20.5667C22.5269 23.3333 23.507 23.3333 24.2557 22.9518C24.9142 22.6163 25.4497 22.0809 25.7852 21.4223C26.1667 20.6736 26.1667 19.6935 26.1667 17.7333V10.2667C26.1667 8.30647 26.1667 7.32638 25.7852 6.57769C25.4497 5.91912 24.9142 5.38369 24.2557 5.04813C23.507 4.66666 22.5269 4.66666 20.5667 4.66666H8.43337C6.47319 4.66666 5.4931 4.66666 4.74441 5.04813C4.08584 5.38369 3.55041 5.91912 3.21485 6.57769C2.83337 7.32638 2.83337 8.30647 2.83337 10.2667V17.7333C2.83337 19.6935 2.83337 20.6736 3.21485 21.4223C3.55041 22.0809 4.08584 22.6163 4.74441 22.9518C5.4931 23.3333 6.47319 23.3333 8.43337 23.3333Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    </div>
    <div class="w-full text-center text-gray-900 text-2xl font-semibold">
        Check your email
    </div>
    <div class="w-full text-center">
        <span class="text-slate-600 text-base font-normal">We've sent a verification code to </span>
        <span class="text-slate-600 text-base font-medium">{{email}}</span>
    </div>
    <div class="w-full flex justify-center gap-2 sm:gap-4">
        <input (keydown)="onKey($event)" (input)="onInput($event)" (paste)="onPaste($event)" type="tel" pattern="[0-9]" maxlength="1" class="w-12 sm:w-16 h-12 sm:h-16 px-0.5 sm:px-2 py-0.5 bg-white rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-600 border-2 border-primary-600 focus:border-primary-600 target:border-primary-600 text-center text-purple-600 text-4xl sm:text-5xl font-medium" />
        <input (keydown)="onKey($event)" (input)="onInput($event)" type="tel" pattern="[0-9]" maxlength="1" class="w-12 sm:w-16 h-12 sm:h-16 px-0.5 sm:px-2 py-0.5 bg-white rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-600 border-2 border-primary-600 focus:border-primary-600 target:border-primary-600 text-center text-purple-600 text-4xl sm:text-5xl font-medium" />
        <input (keydown)="onKey($event)" (input)="onInput($event)" type="tel" pattern="[0-9]" maxlength="1" class="w-12 sm:w-16 h-12 sm:h-16 px-0.5 sm:px-2 py-0.5 bg-white rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-600 border-2 border-primary-600 focus:border-primary-600 target:border-primary-600 text-center text-purple-600 text-4xl sm:text-5xl font-medium" />
        <input (keydown)="onKey($event)" (input)="onInput($event)" type="tel" pattern="[0-9]" maxlength="1" class="w-12 sm:w-16 h-12 sm:h-16 px-0.5 sm:px-2 py-0.5 bg-white rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-600 border-2 border-primary-600 focus:border-primary-600 target:border-primary-600 text-center text-purple-600 text-4xl sm:text-5xl font-medium" />
        <input (keydown)="onKey($event)" (input)="onInput($event)" type="tel" pattern="[0-9]" maxlength="1" class="w-12 sm:w-16 h-12 sm:h-16 px-0.5 sm:px-2 py-0.5 bg-white rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-600 border-2 border-primary-600 focus:border-primary-600 target:border-primary-600 text-center text-purple-600 text-4xl sm:text-5xl font-medium" />
        <input (keydown)="onKey($event)" (input)="onInput($event)" type="tel" pattern="[0-9]" maxlength="1" class="w-12 sm:w-16 h-12 sm:h-16 px-0.5 sm:px-2 py-0.5 bg-white rounded-lg focus:outline-none focus:ring-1 focus:ring-primary-600 border-2 border-primary-600 focus:border-primary-600 target:border-primary-600 text-center text-purple-600 text-4xl sm:text-5xl font-medium" />
    </div>
    <div *ngIf="errorMessage">
        <p class="text-center text-red-700">{{errorMessage}}</p>
    </div>
    <div class="w-full">
        <button [disabled]="loading" (click)="verify()" class="w-full h-11 px-4 py-2.5 bg-purple-600 disabled:bg-purple-400 rounded-lg shadow border border-purple-600 disabled:border-purple-400 justify-center items-center gap-2 inline-flex text-white text-base font-semibold">Verify email</button>
    </div>
    <div *ngIf="!resent" class="flex flex-col items-center">
        <div class="text-slate-600 text-sm font-normal">Didn't receive the email?</div>
        <div class="text-purple-900 text-sm font-semibold cursor-pointer" (click)="login(true)">Click to resend</div>
    </div>
    <div class="flex gap-2 items-center cursor-pointer" (click)="goBack()">
        <span class="w-5 h-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M16.3333 10H4.66663M4.66663 10L10.5 15.8334M4.66663 10L10.5 4.16669" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        <span class="text-slate-600 text-sm font-semibold">Go back</span>
    </div>
</div>