import { Component } from '@angular/core';
import { StudentService } from '../../../services/student.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  student: any;
  constructor(private studentService: StudentService, private authService: AuthService) {
    this.studentService.getStudentDetail().subscribe((student) => this.student = student);
  }

  logout() {
    localStorage.removeItem('studentId');
    this.authService.logout();
  }

}
