import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GymService } from '../../services/gym.service';
import { GymDetails } from '../../models/gym-details.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-stripe-redirect',
  templateUrl: './stripe-redirect.component.html',
  styleUrl: './stripe-redirect.component.scss'
})
export class StripeRedirectComponent implements OnInit {
  gymDetails: GymDetails | undefined;
  email: string | undefined;
  errorMessage: string | undefined;
  loading: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private gymService: GymService, private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    const gymId = this.activatedRoute.snapshot.queryParams['gymId'];

    if (gymId) {
      this.gymService.getGymDetails(gymId).subscribe({
        next: result => {
          this.gymDetails = result;
        },
        error: error => {
          console.error(error.error.code);
        }
      })
    }
  }

  login() {
      if (this.email) {
        if (!this.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          this.errorMessage = "Please enter a valid email.";
          this.loading = false;
          return;
        }
  
        this.loading = true;
        this.authService.passwordlessLogin(this.email).subscribe({ 
          next: () => {
            this.router.navigateByUrl(`login?email=${encodeURIComponent(this.email!)}`);
            this.loading = false;
          }, 
          error: () => { 
            this.loading = false;
          }
        });
      }
    }
}