<div class="flex flex-col w-full items-center gap-4 mt-12 px-8 lg:px-[35%]">
    <div>
        <img class="h-24 w-auto" [src]="gymDetails?.logoUrl"/>
    </div>
    <div class="flex items-center justify-center bg-gray-100">
        <div class="w-full bg-white shadow-md rounded-lg p-6">
          <h1 class="text-2xl font-bold text-center text-gray-800 mb-4">🎉 Congratulations! 🎉</h1>
          <p class="text-gray-600 text-center mb-6">
            You’re now a member of {{gymDetails?.name}}, we’re so happy to have you join us!
          </p>
          <div class="space-y-4 mb-4">
            <div class="flex items-center">
              <span class="text-green-500 mr-3">✍️</span>
              <p class="text-gray-700">
                Next, ensure you’ve reviewed our standard documents before your next class (or have a legal guardian sign on your behalf if you are under the age of 18).
              </p>
            </div>
            <div class="flex items-center">
              <span class="text-green-500 mr-3">📆</span>
              <p class="text-gray-700">
                Then, you can review the session time table and prepare for your next visit!
              </p>
            </div>
            <div class="flex items-center justify-center">
              <p class="text-gray-700">
                Enter your email to get started
              </p>
            </div>
          </div>
          <div class="w-full flex flex-col gap-2">
            <input [(ngModel)]="email" name="email" type="text" placeholder="Enter your email..." [ngClass]="{'w-full border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg w-full mb-4': true, 'hover:bg-gray-50 border-gray-300 focus:border-primary-300 focus:ring-gray-100': !errorMessage, 'focus:ring-red-600 border-red-600 focus:border-red-600 target:border-red-600': errorMessage}" />
            <p *ngIf="errorMessage" class="text-center text-red-700 mb-4">{{errorMessage}}</p>
            <button [disabled]="loading || !email" (click)="login()" class="w-full h-11 px-4 py-2.5 bg-purple-600 disabled:bg-purple-400 rounded-lg shadow border border-purple-600 disabled:border-purple-400 justify-center items-center gap-2 inline-flex text-white text-base font-semibold">Send verification email</button>
          </div>
        </div>
      </div>
</div>