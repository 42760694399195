import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GymDetails } from '../models/gym-details.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GymService {

  constructor(private http: HttpClient) { }

  getGymDetails(gymId: number) {
      return this.http.get<GymDetails>(`${environment.baseUrl}/GymDetails?gymId=${gymId}`);
  }
}
