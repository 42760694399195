<mat-drawer-container class="h-full">
    <mat-drawer #drawer [mode]="isMobile ? 'over' : 'side'" class="w-64 sm:w-48">
        <div class="flex flex-col h-full">
            <div class="p-4 flex-grow">
                <a
                    routerLink="/"
                    (click)="onNavClick()"
                    class="block rounded-lg px-4 py-2 mb-2 text-gray-500 hover:bg-primary-50 hover:text-primary-700"
                >
                    Calendar
                </a>
                <a 
                    routerLink="/document" 
                    (click)="onNavClick()" 
                    class="block rounded-lg px-4 py-2 text-gray-500 hover:bg-primary-50 hover:text-primary-700"
                >
                    Documents
                </a>
            </div>
            <div class="p-4">
                @if (showStudentSelector) {
                <button 
                    (click)="switchStudent()" 
                    class="block w-full text-left rounded-lg px-4 py-2 text-gray-500 hover:bg-primary-50 hover:text-primary-700"
                >
                    Switch Profile
                </button>
                }
                <button 
                    (click)="logout()" 
                    class="block w-full text-left rounded-lg px-4 py-2 text-gray-500 hover:bg-primary-50 hover:text-primary-700"
                >
                    Logout
                </button>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content class="h-full">
        <ng-content></ng-content>
    </mat-drawer-content>
</mat-drawer-container>
