import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { StudentService } from '../../../services/student.service';
import { Student } from '../../../models/student.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GymDetails } from '../../../models/gym-details.model';
import { AuthService } from '../../../services/auth.service';

export enum SelectorStep {
  Gym = 1,
  Student
}

@Component({
  selector: 'app-student-selector',
  templateUrl: './student-selector.component.html',
  styleUrl: './student-selector.component.scss'
})
export class StudentSelectorComponent implements OnInit {
  private studentService = inject(StudentService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private location = inject(Location);
  private authService = inject(AuthService);

  students: Student[] = [];
  gyms: GymDetails[] = [];
  currentStep = SelectorStep.Gym;
  selectorStep = SelectorStep;
  #redirectUrl = '';
  #currentGym: GymDetails | undefined;

  get currentGymStudents() {
    if (this.#currentGym) {
      return this.students.filter(x => x.gymId == this.#currentGym!.id);
    }

    return [];
  }

  ngOnInit(): void {
    this.#redirectUrl = this.activatedRoute.snapshot.queryParams['redirect'];

    this.studentService.getStudentSelector().subscribe({
      next: (students) => {
        if (students.length === 1) {
          this.selectStudent(students[0].uid);
          return;
        }
        students.forEach(student => {
          if (!this.gyms.find(x => x.id === student.gymId)) {
            this.gyms.push({id: student.gymId, name: student.gymName, logoUrl: student.gymLogoUrl});
          }
        });

        if (this.gyms.length === 1) {
          this.#currentGym = this.gyms[0];
          this.currentStep = SelectorStep.Student;
        }

        this.students = students;
      }
    })
  }

  selectGym(id: number) {
    this.#currentGym = this.gyms.find(x => x.id === id);
    this.currentStep = SelectorStep.Student;
  }

  selectStudent(id: string) {
    localStorage.setItem('studentId', id);
    this.router.navigateByUrl(this.#redirectUrl);
  }

  goBack() {
    if (this.currentStep == SelectorStep.Gym || this.gyms.length == 1) {
      this.location.back();
    }

    this.currentStep = SelectorStep.Gym;
  }
}
