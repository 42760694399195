import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthToken } from '../models/login-model';
import { BehaviorSubject, concatMap, of, switchMap, iif, mergeMap, take, tap } from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly isAuthenticated$ = new BehaviorSubject<boolean>(false);

  authTokenLocalStorageKey: string = "FightCloudAuthToken";
  authRefreshLocalStorageKey: string = "FightCloudAuthRefresh";
  authExpiryLocalStorageKey: string = "FightCloudAuthExpiry";

  constructor(private http: HttpClient, private router: Router) { 
    const expiry = this.getExpiry();
    const token = this.getToken();

    if (expiry && token) {
      const expiryDate = moment(expiry);
      if (expiryDate.utc() > moment.utc()) {
        this.isAuthenticated$.next(true);
      } else {
        const refreshToken = this.getRefresh();

        if (refreshToken) {
          this.refreshToken(refreshToken).pipe(
            take(1),
            tap({next: (token) => this.setAuth(token), error: () => this.logout() })
          ).subscribe();
        }
      }
    }
  }

  passwordlessLogin(email: string) {
    return this.http.post(`${environment.baseUrl}/authentication/passwordless`, { email: email }, { headers: { skip: 'true' }});
  }

  passwordlessVerify(email: string, token: string) {
    return this.http.post<AuthToken>(`${environment.baseUrl}/authentication/passwordless-verify`, { email: email, token: token }, { headers: { skip: 'true' }});
  }

  refreshToken(token: string) {
    return this.http.post<AuthToken>(`${environment.authBaseUrl}/refresh`, { refreshToken: token }, { headers: { skip: 'true' }});
  }

  setAuth(token: AuthToken) {
    localStorage.setItem(this.authTokenLocalStorageKey, token.accessToken);
    localStorage.setItem(this.authRefreshLocalStorageKey, token.refreshToken);
    localStorage.setItem(this.authExpiryLocalStorageKey, moment().utc().add(token.expiresIn, 's').toString());
    this.isAuthenticated$.next(true);
  }

  getToken() {
    return localStorage.getItem(this.authTokenLocalStorageKey);
  }

  getRefresh() {
    return localStorage.getItem(this.authRefreshLocalStorageKey);
  }

  getExpiry() {
    return localStorage.getItem(this.authExpiryLocalStorageKey);
  }

  logout() {
    localStorage.removeItem(this.authTokenLocalStorageKey);
    localStorage.removeItem(this.authRefreshLocalStorageKey);
    localStorage.removeItem(this.authExpiryLocalStorageKey);
    localStorage.removeItem('studentId');
    this.isAuthenticated$.next(false);
    
    location.reload();
  }
}
