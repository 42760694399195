import { Component, OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';
import { WindowService } from '../../services/window.service';
import { StudentService } from '../../services/student.service';
import { GymDetails } from '../../models/gym-details.model';

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit {
  isMobile: boolean = false;
  sideNavToggled: boolean = false;
  gymDetails: GymDetails | undefined;
  showStudentSelector = false;

  constructor(private windowService: WindowService, private studentService: StudentService) {}

  ngOnInit(): void {
    initFlowbite();
    this.windowService.isMobile.subscribe(isMobile => this.isMobile = isMobile);

    this.studentService.getStudentGymDetails().subscribe({
      next: result => this.gymDetails = result
    });

    this.studentService.getStudentSelector().subscribe({
      next: result => this.showStudentSelector = result.length > 1
    })
  }

  onToggleSideNav() {
    this.sideNavToggled = !this.sideNavToggled;
  }

}
