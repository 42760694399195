<div class="flex justify-center">
    <form [formGroup]="signatureForm" class="max-w-screen-xl w-full sm:w-10/12 mt-4" *ngIf="signStudentDocument">
        <div class="px-4">
            <app-back-button></app-back-button>
        </div>
        <div class="p-4 ql-editor h-auto" [innerHTML]="signStudentDocument.documentContent"></div>
        <div class="mt-4 flex justify-center" *ngIf="signStudentDocument.signatureRequired">
            <h4 class="text-xl">{{guardianSignature ? studentName + ' is under 18, please have a legal guardian over the age of 18 sign this document' : 'Sign below'}}</h4>
        </div>
        <div class="my-4" *ngIf="showDateOfBirth">
            <fc-text-input (click)="date.open()" [matDatepicker]="date" label="Date of birth" (dateChange)="dateChange($event)" placeholder="Date of birth" />
            <mat-datepicker touchUi #date></mat-datepicker>
        </div>
        <div class="my-4" *ngIf="showGuardianName">
            <fc-text-input class="grow" formControlName="signatureName" [label]="guardianSignature ? 'Legal guardian full name' : 'Full name'" [required]="true" [placeholder]="guardianSignature ? 'Legal guardian full name' : 'Full name'" [submitted]="submitted"></fc-text-input>
        </div>
        <div class="my-4" *ngIf="showSignatureComponent">
            <label class="block text-sm font-medium text-gray-700 mb-2">{{guardianSignature ? 'Legal guardian signature' : 'Signature'}} <span class="text-primary-700">*</span></label>
            <app-signature-pad #signaturePad></app-signature-pad>
        </div>
        <div class="flex justify-end" *ngIf="showSignButton">
            <button (click)="clear()" class="focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-2 py-2 text-center text-gray-500 hover:text-white border border-gray-500 hover:bg-gray-600">Clear</button>
        </div>
        <div class="p-4" *ngIf="showSignButton">
            <button #signBtn [disabled]="signaturePadComponent?.signaturePad?.isEmpty() || submitting" (click)="save()" class="h-[38px] w-full text-center select-none hover:cursor-pointer text-white border border-primary-600 bg-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0 font-medium rounded-lg text-sm px-4 py-2.5 flex justify-center items-center space-x-2">Submit</button>
        </div>
    </form>
</div>