<nav class="bg-white border-b p-4 flex justify-between items-center">
    <div class="flex items-center gap-6 pl-2">
        <button (click)="onToggleSideNav()">
            <bars-3-outline-icon class="heroicon-sw-1.5"></bars-3-outline-icon>
        </button>
        <div class="flex items-center gap-3 text-2xl font-medium font-poppins">
            <img class="h-[1.75rem] w-[1.75rem] rounded-full object-cover" [src]="gymLogoUrl"/>
            {{ gymName }}
        </div>
    </div>
</nav>
