<div class="flex flex-col gap-6 pt-8">
    <div class="px-4">
        <span class="text-gray-900 text-lg font-semibold">{{ student?.firstName }}'s documents</span>
    </div>
    <div class="flex flex-col">
        <div (click)="goToDocument(document)" *ngFor="let document of documents" class="px-6 py-4 flex items-center gap-4 border-b first:border-t cursor-pointer" [ngClass]="{'bg-gray-200': document.isCurrent === false || document.isArchived === true}">
            <div *ngIf="document.isCurrent && document.signatureRequired && !document.studentDocumentId && !document.isArchived" class="py-1 px-2 bg-error-50 rounded-2xl border border-error-200">
                <div class="text-center text-error-700 text-xs font-medium leading-none">Sign here</div>
            </div>
            <div>
                <div class="flex flex-col">
                    <div class="text-gray-900 text-sm font-medium">{{document.name}} <span class="text-sm font-base text-gray-600">V{{document.version}}</span></div>
                    <span class="text-gray-600 text-sm font-normal">
                        <span *ngIf="document.isCurrent === true && document.signatureRequired && document.signatureDate">Signed {{document.signatureDate | date:'dd/MM/yyyy'}}</span>
                        <span *ngIf="document.isCurrent === true && document.termsAcceptedAt">Accepted {{document.termsAcceptedAt | date:'dd/MM/yyyy'}}</span>
                        <span *ngIf="document.isCurrent === true && document.signatureRequired && !document.signatureDate">Not signed</span>
                        <span *ngIf="document.isCurrent === false || document.isArchived === true">(old version)</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>