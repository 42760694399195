<div class="flex justify-center">
    <div class="max-w-screen-xl w-full sm:w-10/12 mt-4">
        <div class="px-4">
            <app-back-button></app-back-button>
        </div>
        <div class="p-4 ql-editor h-auto" [innerHTML]="studentDocument?.content"></div>
        <div class="p-4">
            <span class="font-semibold">{{studentDocument?.signedText}}</span>
        </div>
        <div class="my-4">
            <img class="h-40" [src]="studentDocument?.signatureUrl"/>
        </div>
    </div>
</div>