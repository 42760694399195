import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  return inject(AuthService).isAuthenticated$.pipe(
    tap(isAuthenticated => {
      if (!isAuthenticated) {
        router.navigateByUrl(`/login?redirect=${state.url}`);
        return;
      }
      if (!localStorage.getItem('studentId') && !state.url.startsWith('/student-selector')) {
        router.navigateByUrl(`/student-selector?redirect=${state.url}`);
        return;
      }
    })
  );
};
