<div class="flex flex-col w-full items-center gap-2 mt-12 px-8 lg:px-[35%]">
    <div class="text-gray-900 font-semibold text-2xl mb-8">
        {{currentStep === selectorStep.Gym ? 'Select Gym' : 'Select Student' }}
    </div>
    @if (currentStep == selectorStep.Gym) {
    @for(gym of gyms; track gym) {    
        <div class="border rounded-xl w-full flex items-center gap-3 p-4 cursor-pointer" (click)="selectGym(gym.id)">
            <div class="relative inline-flex items-center justify-center min-w-10  w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="select-none font-medium text-gray-600 dark:text-gray-300">
                    <img *ngIf="gym.logoUrl" [src]="gym.logoUrl" />
                    <ng-container *ngIf="!gym.logoUrl">
                        {{ gym.name[0] }}
                    </ng-container>
                </span>
            </div>
            <div class="font-semibold text-gray-900">
                {{gym.name}}
            </div>
        </div>
        }
    }
    @if (currentStep == selectorStep.Student) {
    @for(student of currentGymStudents; track student) {    
        <div class="border rounded-xl w-full flex items-center gap-3 p-4 cursor-pointer" (click)="selectStudent(student.uid)">
            <div class="relative inline-flex items-center justify-center min-w-10 w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="select-none font-medium text-gray-600 dark:text-gray-300">
                    <img *ngIf="student.studentImageUrl" [src]="student.studentImageUrl" />
                    <ng-container *ngIf="!student.studentImageUrl">
                        {{ student.firstName[0] }}{{student.lastName[0]}}
                    </ng-container>
                </span>
            </div>
            <div class="font-semibold text-gray-900">
                {{student.firstName}} {{student.lastName}}
            </div>
        </div>
        }
    }
    <div class="flex items-center gap-2 text-gray-600 text-sm mt-8 cursor-pointer" (click)="goBack()">
        <arrow-left-outline-icon /> Go back
    </div>
</div>