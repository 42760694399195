import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import enAuLocale from '@fullcalendar/core/locales/en-au';
import { BehaviorSubject } from 'rxjs';
import { WindowService } from 'projects/admin/src/app/services/window.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';
import * as moment from 'moment';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements AfterViewInit {
  isMobile = false;
  menuLeft = 0;
  menuTop = 0;
  events$ = new BehaviorSubject<any[]>([]);
  calendarOptions: CalendarOptions = {
    height: '100%',
    initialView: 'timeGridWeek',
    allDaySlot: false,
    expandRows: true,
    slotMinTime: '05:00:00',
    slotMaxTime: '23:00:00',
    plugins: [timeGridPlugin, interactionPlugin],
    locale: enAuLocale,
    datesSet: this.handleWeekChanges.bind(this),
    // eventClick: this.handleEventClick.bind(this),
    // dateClick: this.handleDateClick.bind(this),
    eventClassNames: ['cursor-pointer'],
    buttonText: {
      today: 'Today'
    },
    titleFormat: {
      year: 'numeric',
      month: 'long'
    },
    headerToolbar: {
      start: 'title',
      center: '',
      end: 'today prev,next'
    },
  };

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  constructor(private studentSessionService: SessionService, private router: Router, private ref: ChangeDetectorRef, private windowService: WindowService, private navService: NavService) {
  }
  
  ngAfterViewInit(): void {
    this.windowService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.calendarComponent?.getApi()?.changeView(isMobile ? 'timeGridDay' : 'timeGridWeek');
    });

    this.navService.visible.subscribe(() => {
      setTimeout(() => {
        this.calendarComponent?.getApi()?.updateSize();
      }, 250);
    });
  }

  handleWeekChanges(event: DatesSetArg) {
    const start = event.startStr.split('T')[0];
    const end = event.endStr.split('T')[0];
    this.studentSessionService.getCalendar(start, end).subscribe(results => {
      const events = results.map(x => { 
        const backgroundColor = x.isPrivate ? false ? '#F2F4F7' : '#0284c7' : '#ea580c';
        const borderColor = x.isPrivate && false ? '#D0D5DD' : x.isPrivate ? '#0369a1' : '#c2410c';
        const textColor = x.isPrivate && false ? '#344054' : '#FFFFFF';
        return { id: x.id, title: x.title, start: `${x.date} ${x.startTime}`, end: `${x.date} ${x.endTime}`, backgroundColor: backgroundColor, borderColor: borderColor, textColor: textColor, isDisabled: false }
      });
      this.events$.next(events);
    });
  }

  isDisabled(id: number) {
    const events = this.events$.value;

    return events.find(x => x.id == id).isDisabled;
  }

  formatDateHeaderDay(date: string) {
    return moment(date).format('ddd').toUpperCase();
  }

  formatDateHeaderDate(date: string) {
    return moment(date).format('DD');
  }

  onSwipeLeft() {
    this.calendarComponent?.getApi().next();
  }

  onSwipeRight() {
    this.calendarComponent?.getApi().prev();
  }
}
